<template>
  <!-- Under maintenance-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <logo />

      <h2 class="brand-text text-primary ml-1">
        VTelehealth
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Đang kiểm tra thông tin
        </h2>

        <!-- <p class="mb-3">
          {{ result === true ? 'Đăng nhập thành công. Vui lòng đợi chút để chuyển hướng!' : 'Đăng nhập không thành công' }}
        </p> -->
        <!-- img -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Verify email page"
        />
      </div>
    </div>
  </div>
  <!-- / Under maintenance-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BImg,
} from 'bootstrap-vue';
import Logo from '@/layouts/components/Logo.vue';
import store from '@/store/index';
import useJwt from '@/auth/jwt/useJwt';
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils';
import { ref } from '@vue/composition-api';

export default {
  components: {
    BLink,
    BImg,
    Logo,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/under-maintenance.svg'),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/under-maintenance-dark.svg');
        return this.downImg;
      }
      return this.downImg;
    },
  },
  setup() {
    const { route } = useRouter();
    

    const result = ref(null);

    useJwt.getVneIdToken(route.value.query).then(() => {
      result.value = true;
    }).catch(() => {
      result.value = false;
    });

    return { result };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
